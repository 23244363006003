<template>
  <div style="margin-top:80px;" v-if="this.$route.name === 'AIMO-Platform-Datapoem-ROi'">
    <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
      <!--  <li>
                  <router-link to="/solutions/ul/platform/models">
                    <span>Model</span>
                  </router-link>
                </li> -->
      <li>
        <router-link to="/solutions/dp/platform/">
          <span>KPI</span>
        </router-link>
      </li>
      <li>
        <router-link to="/solutions/dp/platform/allstats">
          <span>Model Result</span>
        </router-link>
      </li>

      <li>
        <router-link to="/solutions/dp/attribution">
          <span>Attribution</span>
        </router-link>
      </li>

      <!-- <li> -->
      <!-- <router-link to="/solutions/dp/platform/stat">
                    <span>Sale Statistics</span>
                  </router-link> -->
      <!-- </li> -->
      <li>
        <router-link to="/solutions/dp/platform/acid-test">
          <span>Acid Test</span>
        </router-link>
      </li>
      <li>
        <router-link to="/solutions/dp/platform/insights">
          <span>Causal Insights</span>
        </router-link>
      </li>
      <li class="ProximaNovaBold">
        ROI Summary
      </li>
    </ul>
    <div class="d-flex flex-row justify-content-end" style="height: 40%">
      <div class="pt-4 d-flex">
        <w-white-dropdown style="width: 350px;" :options="brandDropdown" :placeHolder="'Select'"
          :selectedOption="selectedBrand" :labelText="'Brand'" class="mr-3" @input="selectBrand($event)">
        </w-white-dropdown>
      </div>
      <div class=" pt-4 d-flex justify-content-end">
        <w-white-dropdown :options="mediaDropdown" :placeHolder="'Select'" :selectedOption="selectedMedia"
          :labelText="'Media'" class="mr-3" @input="selectMedia($event)"></w-white-dropdown>
      </div>
    </div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content mb-5">
        <div class="container-fluid mt-5">
          <div class="">
            <!--class of above div - row bottom_header w-100 mx-0 mt-4-->
            <div class="w-100 p-0">
              <div class="container-fluid  p-0">
                <div class="card w-100 d-none">
                  <div class="card-body p-0">
                    <div>
                      <w-chartoverview :chartSummary="salseStatics"></w-chartoverview>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- Marketing ROI -->
            <div class="w-100 d-none">
              <div class="container-fluid mt-5 p-0">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-6 fit-content border_right">
                      <div class="card-header font-large ProximaNovaBold" style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        ">
                        Marketing ROI
                      </div>
                      <w-columnchart :chartData="marketingRio" />
                    </div>
                    <div class="col-6 row">
                      <div class="col-6 fit-content">
                        <div class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          " style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          ">
                          Short term ROI
                        </div>
                        <pie-chart :series="shortTermData" :annotation="''" />
                      </div>
                      <div class="col-6 fit-content">
                        <div class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          " style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          ">
                          Long term ROI
                        </div>
                        <pie-chart :series="longTermChartData" :annotation="''" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Investment vs ROI -->
            <div class="w-100 d-none">
              <div class="container-fluid  mt-5 p-0">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <w-columnchart :chartData="investRio" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Investment vs ROI -->

            <w-multibreakdown-chart-data :expandData="invsroiExpandData" :thirdLevelData="invsroiExpandLevelThreeData"
              :dropdownList="invetmentvsroiDropdown" :secondLeveldropdownList="invetmentvsroiDropdown2"
              :selectedOption="secondLevelInvestmentVSRoiTitle"
              :selectedSecondLevelOption="selectedSecondLevelInvestmentROI" :chartData="investRoiData"
              @chartClick="getLevelOneInvsVsRoiData($event)" @chartClick1="getLevelTwoInvsVsRoiData($event)"
              @selectedOption="selectedInvestmentVSRoiDropdown($event)"
              @selectedSecondLevelOption="selectedLevelTwoInvestmentVSRoiDropdown($event)"
              :text="'Investment vs Revenue by Channel '" class="p-0" :showChartBreakdown="isBreakdownOn"
              :showBreakdown="isSecondLevelBreakdownOn">
            </w-multibreakdown-chart-data>

            <!-- TV -->
            <w-chart-data :expandData="tvMedia" :dropdownList="tvdropdown" :selectedOption="tvTitle" :chartData="tvData"
              @chartClick="clickedTv($event)" @selectedOption="selectedTvDropdown($event)" :text="'Television'"
              class="p-0 d-none">
            </w-chart-data>
            <!-- Digital -->
            <w-chart-data :expandData="digitalMedia" :dropdownList="digitaldropdown" :selectedOption="digitalTitle"
              :chartData="digital" @selectedOption="selectedDigitalDropdown($event)" :text="'Digital'"
              @chartClick="clickedDigital($event)" class="p-0 d-none">
            </w-chart-data>
            <!-- Print -->
            <w-chart-data :expandData="printMedia" :dropdownList="printdropdown" :selectedOption="printTitle"
              :chartData="print" @selectedOption="selectedPrintDropdown($event)" @chartClick="clickedPrint($event)"
              :text="'Print '" class="p-0 d-none">
            </w-chart-data>
            <!-- Radio -->
            <w-chart-data :expandData="radioMedia" :dropdownList="radiodropdown" :selectedOption="radioTitle"
              :chartData="radio" @selectedOption="selectedRadioDropdown($event)" @chartClick="clickedRadio($event)"
              :text="'Radio'" class="p-0 d-none">
            </w-chart-data>
            <!-- Sponsorship -->
            <w-chart-data :expandData="sponsorshipMedia" :dropdownList="sponsorshipdropdown"
              :selectedOption="sponsorshipTitle" :chartData="sponsorship" :text="'Sponsorship'" class="p-0 d-none"
              :showBreakdown="false">
            </w-chart-data>
            <!-- Content -->
            <w-chart-data :expandData="contentMedia" :dropdownList="contentdropdown" :selectedOption="contentTitle"
              :chartData="content" @selectedOption="selectedContentDropdown($event)" :text="'Content'"
              @chartClick="clickedContent($event)" class="p-0 d-none">
            </w-chart-data>
            <!-- Billboard -->
            <!--<w-chart-data
              :expandData="billboardMedia"
              :dropdownList="billboarddropdown"
              :selectedOption="billboardTitle"
              :chartData="billboard"
              @selectedOption="selectedBillboardDropdown($event)"
              :text="'Billboard'"
              @chartClick="clickedBillboard($event)"
              class="p-0"
            >
            </w-chart-data>-->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// import Dropdown from "@/widgets/Dropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
const aimoSolutionsServices = new AimoSolutionsServices();
import ChartWiseData from "@/components/Summary/ChannelWiseData.vue";
import MultiBreakdownChannelWiseData from "@/components/Summary/MultiBreakdownChannelWiseData.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";

import { AimoDatapoemServices } from "@/services/AimoDatapoemServices.js";
const aimoDatapoemService = new AimoDatapoemServices();


// import { aimoDatapoemService } from "@/services/aimoDatapoemService.js";
// const aimoDatapoemService = new aimoDatapoemService();

export default {
  name: "Summary",
  components: {
    // "w-dropdown": Dropdown,
    "w-white-dropdown": WhiteDropdown,
    "w-chart-data": ChartWiseData,
    "w-multibreakdown-chart-data": MultiBreakdownChannelWiseData,
    // "w-tab": Tab,
    "w-chartoverview": ChartOverview,
    "w-columnchart": ColumnChart,
    PieChart,
  },
  data() {
    return {
      isSecondLevelBreakdownOn: false,

      clientId: sessionStorage.getItem("subId"),
      marketType: localStorage.getItem("selectedKPISource"),
      isBreakdownOn: true,
      selectedLevelThreeData: {},
      printdropdown: [],
      radiodropdown: [],
      sponsorshipdropdown: [],
      billboarddropdown: [],
      digitalBreakdown: false,
      printBreakdown: false,
      radioBreakdown: false,
      tvBreakdown: false,
      contentBreakdown: false,
      colors: [
        "#61f9d4",
        "#ff4081",
        "#ffd740",
        "#536dfe",
        "#ffab40",
        "#e53935",
        "#ff6e40",
        "#148f3a",
      ],
      brandDropdown: [
        // { text: "Tresemme", id: "tresemme" },
        // { text: "Dove", id: "dove" },
      ],
      mediaDropdown: [
        // { text: "Offline", id: "offline" },
        // { text: "Online", id: "online" },
        // { text: "Overall", id: "overall" },
      ],
      selectedBrand: {
        // text: localStorage.getItem("selectedBrandKPI"),
        // id: localStorage.getItem("selectedBrandKPI").toLowerCase(),
      },
      selectedMedia: {
        // text: localStorage.getItem("selectedMediaKPI").toLowerCase(),
        // id: localStorage.getItem("selectedMediaKPI").toLowerCase(),
      },
      shortTermData: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [
            {
              name: "Content",
              y: 23,
              color: "#ffdd7c",
            },
            {
              name: "Digital",
              y: 77,
              sliced: true,
              selected: true,
              color: "#faacfe",
            },
          ],
        },
      ],
      longTermChartData: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [
            {
              name: "Content",
              y: 23,
              color: "#ffdd7c",
            },
            {
              name: "Digital",
              y: 77,
              sliced: true,
              selected: true,
              color: "#faacfe",
            },
          ],
        },
      ],
      digitalTitle: {},
      printTitle: {},
      sponsorshipTitle: {},
      billboardTitle: {},
      radioTitle: {},
      tvTitle: {},
      secondLevelInvestmentVSRoiTitle: {},
      selectedSecondLevelInvestmentROI: {
        id: "Digital Display",
        text: "Digital Display",
      },
      tvTitle1: {},
      tvdropdown: [],
      invetmentvsroiDropdown: [],
      invetmentvsroiDropdown2: [
        { id: "Digital Display", text: "Digital Display" },
      ],
      invetmentvsroiDropdown3: [],

      digitaldropdown: [],
      contentTitle: {},
      contentdropdown: [
        { text: "Owned content", id: "1" },
        { text: "Paid Media", id: "2" },
      ],
      activeTab: "ROI summary",
      tabs: [
        {
          category: "Sales Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI summary",
        },
      ],
      activeFrom: 1,
      salseStatics: [
        {
          name: "Marketing ROI",
          value: "3.32x",
          className: "blue-pastel",
          image: "Marketing ROI.svg",
        },
        {
          name: "Investment",
          value: "291.01Cr",
          className: "green-pastel",
          image: "Investment.svg",
        },
        {
          name: "ROI",
          value: "967.52Cr",
          className: "pink-pastel",
          image: "ROI.svg",
        },
      ],
      marketingRio: {
        data: [
          {
            name: "Base Sales",
            data: [0, 360],
          },
          {
            name: "Short Term ROI",
            data: [200, 240],
          },
          {
            name: "Long Term ROI",
            data: [0, 240],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffab40", "#536dfe"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      investRio: {
        data: [
          {
            name: "Investment",
            data: [30, 60, 30, 12, 30, 43, 50],
          },
          {
            name: "ROI",
            data: [60, 15, 50, 50, 20, 30, 45],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Investment vs ROI by Channel (Cr)",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },

      investRoiData: {
        data: [
          {
            name: "Investment",
            data: [],
          },
          {
            name: "ROI",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      invsroiExpandData: {
        data: [
          {
            name: "Investment",
            data: [],
          },
          {
            name: "Revenue",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },

      invsroiExpandLevelThreeData: {
        data: [
          {
            name: "Investment",
            data: [],
          },
          {
            name: "Revenue",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },

      tvData: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60],
          },
          {
            name: "ROI",
            data: [50, 30, 50],
          },
        ],
        xAxis: {
          categories: ["Digital", "TV", "Print"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedTv(data.point.category);
                },
              },
            },
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      print: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedPrint(data.point.category);
                },
              },
            },
          },
        },
        color: ["#d81b60", "#1e88e5"],
        subtitle: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        legend: {},
      },
      digital: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedDigital(data.point.category);
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#536dfe"],
        subtitle: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        legend: {},
      },
      radio: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedRadio(data.point.category);
                },
              },
            },
          },
        },
        color: ["#5e35b1", "#fb8c00"],
        subtitle: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        legend: {},
      },
      sponsorship: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedSponsorship(data.point.category);
                },
              },
            },
          },
        },
        color: ["#ff6e40", "#ffab40"],
        subtitle: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        legend: {},
      },
      billboard: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedBillboard(data.point.category);
                },
              },
            },
          },
        },
        color: ["#ffd740", "#00897b"],
        subtitle: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
        legend: {},
      },
      content: {
        data: [
          {
            name: "Investment",
            data: [30, 43],
          },
          {
            name: "ROI",
            data: [48, 12],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            cursor: "pointer",
            point: {
              events: {
                click: (data) => {
                  this.clickedContent(data.point.category);
                },
              },
            },
          },
        },
        color: ["#40c4ff", "#ffab40"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      tvMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60],
          },
          {
            name: "ROI",
            data: [50, 30, 50],
          },
        ],
        xAxis: {
          categories: ["Digital", "TV", "Print"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      contentMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      billboardMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      digitalMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      printMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      sponsorshipMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      radioMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
      market: "",
    };
  },
  created() {
    this.getBrands(this.clientId)

    this.market = JSON.parse(sessionStorage.getItem("solutionsKpiMarket"));
    // this.getStaticsData()
    // this.getMarketingROI();
    // this.getShortTerm();
    // this.getLongTermData();
    // this.getInvestVsROI();
    // this.secondLevelData(
    //   "investRoiData",
    //   "investmentvsroi",
    //   "/b2c/in/",
    //   "allindia",
    //   "invetmentvsroiDropdown",
    //   "tvTitle",
    //   "invsroiExpandData"
    // );

    // this.secondLevelData(
    //   "print",
    //   "investment and roi print",
    //   "/b2c/in/",
    //   this.market.id,
    //   "printdropdown",
    //   "printTitle",
    //   "printMedia"
    // );
    // this.secondLevelData(
    //   "radio",
    //   "investment and roi radio",
    //   "/b2c/in/",
    //   this.market.id,
    //   "radiodropdown",
    //   "radioTitle",
    //   "radioMedia"
    // );
    // this.secondLevelData(
    //   "sponsorship",
    //   "investment and roi sponsorship",
    //   "/b2c/in/",
    //   this.market.id,
    //   "sponsorshipdropdown",
    //   "sponsorshipTitle",
    //   "sponsorshipMedia"
    // );
    // this.secondLevelData(
    //   "tvData",
    //   "investment and roi tv",
    //   "/b2c/in/",
    //   this.market.id,
    //   "tvdropdown",
    //   "tvTitle",
    //   "tvMedia"
    // );
    // this.secondLevelData(
    //   "content",
    //   "investment and roi content",
    //   "/b2c/in/",
    //   "allindia",
    //   "contentdropdown",
    //   "contentTitle",
    //   "contentMedia"
    // );
    // this.secondLevelData(
    //   "billboard",
    //   "investment and roi billboard",
    //   "/b2c/in/",
    //   this.market.id,
    //   "billboarddropdown",
    //   "billboardTitle",
    //   "billboardMedia"
    // );
  },
  methods: {
    getBrands(cliendId) {
      this.brandDropdown = []
      this.mediaDropdown = []
      aimoDatapoemService.getClientBrandDetails(cliendId).then((res) => {
        let dropdown_labels = Object.keys(res[0])
        this.dropdownLabel = dropdown_labels[1]

        let brand = res[0].Brand
        let kpi = res[0][this.dropdownLabel]

        for (var brands = 0; brands < brand.length; brands++) {
          this.brandDropdown.push({ id: brand[brands], text: brand[brands] })
        }

        for (var kpis = 0; kpis < kpi.length; kpis++) {
          this.mediaDropdown.push({ id: kpi[kpis], text: kpi[kpis] })
        }

        // const brand_data = this.$router.history.current.params.brand;
        // const media_data = this.$router.history.current.params.media;

        // if (brand_data.text !== undefined && brand_data !== undefined && media_data.text !== undefined && media_data !== undefined) {
        //   this.selectedBrand = brand_data
        //   this.selectedMedia = media_data
        //   console.log(brand_data, media_data)

        // } else {





        this.selectedBrand = this.brandDropdown[0]
        this.selectedMedia = this.mediaDropdown[0]
        // }

        this.getInvsVsRoiData(this.selectedBrand.text, this.selectedMedia.text);


        // this.getInvsVsRoiData()

        // console.log(this.selectedMedia)
      }).catch((err) => {
        alert(err)
      })
    },
    getInvsVsRoiData(brand, media) {
      console.log(brand, media)
      aimoDatapoemService
        .getChartData(
          brand,
          media,
          "investmentvsrevenue",
          this.clientId
        )
        .then((res) => {
          this.invetmentvsroiDropdown = [];
          this.investRoiData.data[0].data = [];
          this.investRoiData.data[1].data = [];
          this.investRoiData.xAxis.categories = [];
          for (var i = 0; i < res.response.levelOne.length; i++) {
            this.investRoiData.data[0].data.push(
              res.response.levelOne[i].Investment
            );
            this.investRoiData.data[1].data.push(res.response.levelOne[i].ROI);
            this.investRoiData.xAxis.categories.push(
              res.response.levelOne[i].name
            );
            this.invetmentvsroiDropdown.push({
              id: res.response.levelOne[i].name,
              text: res.response.levelOne[i].name,
            });
          }
        });
    },

    getLevelOneInvsVsRoiData(data) {
      this.secondLevelInvestmentVSRoiTitle = data;
      this.isBreakdownOn = false;

      aimoDatapoemService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "investmentvsrevenue",
          this.clientId
        )
        .then((res) => {
          const selectedChannel = res.response.levelTwo.filter((item) =>
            item.name.includes(data.text)
          );

          selectedChannel.length === 0
            ? (this.isSecondLevelBreakdownOn = false)
            : (this.isSecondLevelBreakdownOn = true);

          this.invsroiExpandData.data[0].data = [];
          this.invsroiExpandData.data[1].data = [];
          this.invsroiExpandData.xAxis.categories = [];

          for (let i = 0; i < selectedChannel[0].value.length; i++) {
            this.invsroiExpandData.data[0].data.push(
              selectedChannel[0].value[i].Investment
            );
            this.invsroiExpandData.data[1].data.push(
              selectedChannel[0].value[i].ROI
            );

            this.invsroiExpandData.xAxis.categories.push(
              selectedChannel[0].value[i].name
            );
          }
        });
    },

    getLevelTwoInvsVsRoiData(data) {
      aimoDatapoemService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "investmentvsrevenue",
          this.clientId
        )
        .then((res) => {
          const selectedChannel = res.response.levelThree.filter((item) =>
            item.name.includes(data.text)
          );

          if (selectedChannel[0].value.length !== 0) {
            this.isBreakdownOn = true;
            this.invsroiExpandLevelThreeData.data[0].data = [];
            this.invsroiExpandLevelThreeData.data[1].data = [];
            this.invsroiExpandLevelThreeData.xAxis.categories = [];

            for (let i = 0; i < selectedChannel[0].value.length; i++) {
              this.invsroiExpandLevelThreeData.data[0].data.push(
                selectedChannel[0].value[i].Investment
              );
              this.invsroiExpandLevelThreeData.data[1].data.push(
                selectedChannel[0].value[i].ROI
              );

              this.invsroiExpandLevelThreeData.xAxis.categories.push(
                selectedChannel[0].value[i].name
              );
            }
          } else {
            this.isBreakdownOn = false;
          }
        });
    },

    selectedInvestmentVSRoiDropdown(data) {
      aimoDatapoemService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "investmentvsrevenue",
          this.clientId
        )
        .then((res) => {
          const selectedChannel = res.response.levelTwo.filter((item) =>
            item.name.includes(data.text)
          );

          this.invsroiExpandData.data[0].data = [];
          this.invsroiExpandData.data[1].data = [];
          this.invsroiExpandData.xAxis.categories = [];

          for (let i = 0; i < selectedChannel[0].value.length; i++) {
            this.invsroiExpandData.data[0].data.push(
              selectedChannel[0].value[i].Investment
            );
            this.invsroiExpandData.data[1].data.push(
              selectedChannel[0].value[i].ROI
            );

            this.invsroiExpandData.xAxis.categories.push(
              selectedChannel[0].value[i].name
            );
          }
        });
    },

    selectedLevelTwoInvestmentVSRoiDropdown(data) {
      aimoDatapoemService
        .getChartData(
          this.selectedBrand.text,
          this.selectedMedia.id,
          "investmentvsrevenue",
          this.clientId
        )
        .then((res) => {
          this.invetmentvsroiDropdown2 = [];

          for (let i = 0; i < res.response.levelThree.length; i++) {
            this.invetmentvsroiDropdown2.push({
              text: res.response.levelThree[i].name,
              id: i,
            });
          }
          this.selectedSecondLevelInvestmentROI = data;
          this.getLevelTwoInvsVsRoiData(data);
        });
    },

    saveToLocal() {
      // if (this.selectedMedia.id && this.selectedBrand.id !== null) {
      localStorage.setItem(
        "selectedKPISource",
        `${this.selectedBrand.id}_${this.selectedMedia.id}`
      );
      this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
      localStorage.setItem("selectedBrandKPI", this.selectedBrand.text);

      this.getInvsVsRoiData(this.selectedBrand.text, this.selectedMedia.text);

      // localStorage.setItem("selectedBrandKPI", this.selectedKPISource.text)
      // }
    },

    selectBrand(e) {
      this.selectedBrand = e;
      this.isSecondLevelBreakdownOn = false
      this.isBreakdownOn = false
      this.saveToLocal();
    },

    selectMedia(e) {
      this.isSecondLevelBreakdownOn = false
      this.isBreakdownOn = false
      this.selectedMedia = e;
      // this.addAllTimeData();
      // this.addsalseStatics();
      this.saveToLocal();
      // this.callApis();
    },

    clickedDigital(data) {
      this.digitalTitle = data;
      this.getLevelThreeData(
        "digitalMedia",
        data.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "digital",
        true
      );
    },
    clickedPrint(data) {
      this.printTitle = data;
      this.getLevelThreeData(
        "printMedia",
        data.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "print",
        false
      );
    },
    clickedRadio(data) {
      this.radioTitle = data;
      this.getLevelThreeData(
        "radioMedia",
        data.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "radio",
        false
      );
    },
    clickedSponsorship(data) {
      this.sponsorshipTitle = data;
      this.getLevelThreeData(
        "sponsorshipMedia",
        data.text.toLowerCase(),
        "/b2c/in/",
        this.market.id,
        "",
        false
      );
    },
    clickedContent(data) {
      this.contentTitle = data;
      this.getLevelThreeData(
        "contentMedia",
        data.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "content",
        false
      );
    },
    clickedBillboard(data) {
      this.billboardTitle = data;
      this.getLevelThreeData(
        "billboardMedia",
        data.text.toLowerCase(),
        "/b2c/in/",
        this.market.id,
        "",
        false
      );
    },
    getLevelThreeData(
      dataVariable,
      param,
      inst,
      market,
      extraText,
      removeSpace
    ) {
      var param1 = param;
      if (removeSpace) {
        param1 = param.replace(/\s/g, "");
      }
      if (param1 === "googleads") {
        param1 = "goodgleads";
      } else if (param1 === "programmatic") {
        param1 = "programatics";
      } else if (param1 === "broadcast tv") {
        param1 = "broadcast";
      } else if (param1 === "paid media") {
        param1 = "paid";
      } else if (param1 === "owned content") {
        param1 = "owned";
      } else if (param1 === "magazines") {
        param1 = "magazine";
      } else if (param1 === "atlanta") {
        param1 = "atalanta";
      } else if (param1 === "sports") {
        param1 = "sports games";
      } else if (param1 === "pos displays") {
        param1 = "pos display";
      } else if (param1 === "andhra pradesh") {
        param1 = "ap";
      } else if (param1 === "malayalam") {
        param1 = "malyalam";
      } else if (param1 === "gujarathi") {
        param1 = "gujrati";
      } else if (param1 === "bangalore") {
        param1 = "banagalore";
      } else if (param1 === "tv") {
        param1 = "tvroiinvestmentvsroi";
      } else if (param1 === "aggregators") {
        param1 = "aggregatorsroiinvestmentvsroi";
      } else if (param1 === "digital") {
        param1 = "digitalroiinvestmentvsroi";
      } else if (param1 === "radio") {
        param1 = "radioroiinvestmentvsroi";
      } else if (param1 === "print") {
        param1 = "printroiinvestmentvsroi";
      }
      aimoSolutionsServices
        .getChartData("roi summary", inst, extraText + param1, market)
        .then((res) => {
          this[dataVariable].data[0].data = [];
          this[dataVariable].data[1].data = [];
          this[dataVariable].xAxis.categories = [];
          if (
            dataVariable === "digitalMedia" ||
            dataVariable === "contentMedia" ||
            dataVariable === "invsroiExpandData" ||
            dataVariable === "printMedia" ||
            dataVariable === "radioMedia"
          ) {
            for (var i = 0; i < res.length; i++) {
              this[dataVariable].data[0].data.push(res[i].Investment);
              this[dataVariable].data[1].data.push(res[i].ROI);
              this[dataVariable].xAxis.categories.push(res[i].name);
            }
          } else {
            for (i = 0; i < res.length; i++) {
              this[dataVariable].data[0].data.push(res[i].Investment);
              this[dataVariable].data[1].data.push(res[i].ROI);
              this[dataVariable].xAxis.categories.push(res[i].name);
            }
          }
        });
    },
    secondLevelData(
      dataVariable,
      param,
      inst,
      market,
      dropdownList,
      selectDropdown,
      thirdLevelData
    ) {
      aimoSolutionsServices
        .getChartData("roi summary", inst, param, market)
        .then((res) => {
          this[dataVariable].data[0].data = [];
          this[dataVariable].data[1].data = [];
          this[dataVariable].xAxis.categories = [];
          this[dropdownList] = [];
          for (var i = 0; i < res.length; i++) {
            this[dataVariable].data[0].data.push(res[i].Investment);
            this[dataVariable].data[1].data.push(res[i].ROI);
            this[dataVariable].xAxis.categories.push(res[i].name);
            if (res[i].name !== "Minneapolis") {
              this[dropdownList].push({
                text: res[i].name,
                id: res[i].name,
                iconHTML: '<i class="fab fa-linkedin"></i>',
              });
            }
            console.log(selectDropdown + thirdLevelData);

            // if (i === res.length - 1) {
            //   this[selectDropdown] = this[dropdownList][0];
            //   if (param === "investment and roi digital") {
            //     this.getLevelThreeData(
            //       thirdLevelData,
            //       this[selectDropdown].text.toLowerCase(),
            //       "/b2c/in/",
            //       "no",
            //       "digital",
            //       true
            //     );
            //   } else if (
            //     param === "investment and roi print" ||
            //     param === "investment and roi radio" ||
            //     param === "investment and roi sponsorship" ||
            //     param === "investment and roi billboard"
            //   ) {
            //     this.getLevelThreeData(
            //       thirdLevelData,
            //       this[selectDropdown].text.toLowerCase(),
            //       "/b2c/in/",
            //       this.market.id,
            //       "",
            //       false
            //     );
            //   } else if (param === "investment and roi tv") {
            //     var name;
            //     if (
            //       this[selectDropdown].text.toLowerCase() === "broadcast tv"
            //     ) {
            //       name = "broadcast";
            //     } else {
            //       name = this[selectDropdown].text.toLowerCase();
            //     }
            //     this.getLevelThreeData(
            //       thirdLevelData,
            //       name,
            //       "/b2c/in/",
            //       this.market.id,
            //       "",
            //       false
            //     );
            //   } else if (param === "investment and roi content") {
            //     this.getLevelThreeData(
            //       thirdLevelData,
            //       this[selectDropdown].text.toLowerCase(),
            //       "/b2c/in/",
            //       "no",
            //       "content",
            //       false
            //     );
            //   }
            // }
          }
        });
    },
    getInvestVsROI() {
      aimoSolutionsServices
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "investmentvsroi",
          this.market.id.toLowerCase()
        )
        .then((res) => {
          this.investRio.data[0].data = [];
          this.investRio.data[1].data = [];
          this.investRio.xAxis.categories = [];
          for (var i = 0; i < res.length; i++) {
            this.investRio.data[0].data.push(res[i].Investment);
            this.investRio.data[1].data.push(res[i].ROI);
            this.investRio.xAxis.categories.push(res[i].name);
          }
        });
    },
    getLongTermData() {
      aimoSolutionsServices
        .getChartData("roi summary", "/b2c/in/", "longterm", "no")
        .then((res) => {
          this.longTermChartData[0].data = [];
          for (var i = 0; i < res.length; i++) {
            if (i === 0) {
              this.longTermChartData[0].data.push({
                name: res[i].name,
                y: res[i].value,
                sliced: true,
                selected: true,
                color: this.colors[i],
              });
            } else {
              this.longTermChartData[0].data.push({
                name: res[i].name,
                y: res[i].value,
                color: this.colors[i],
              });
            }
          }
        });
    },
    getShortTerm() {
      aimoSolutionsServices
        .getChartData("roi summary", "/b2c/in/", "shortterm", "no")
        .then((res) => {
          this.shortTermData[0].data = [];
          for (var i = 0; i < res.length; i++) {
            if (i === 0) {
              this.shortTermData[0].data.push({
                name: res[i].name,
                y: res[i].value,
                sliced: true,
                selected: true,
                color: this.colors[i],
              });
            } else {
              this.shortTermData[0].data.push({
                name: res[i].name,
                y: res[i].value,
                color: this.colors[i],
              });
            }
          }
        });
    },
    getMarketingROI() {
      this.marketingRio.data[0].data = [];
      this.marketingRio.data[1].data = [];
      this.marketingRio.data[2].data = [];
      this.marketingRio.xAxis.categories = [];
      aimoSolutionsServices
        .getChartData(
          "roi summary",
          "/b2c/in/",
          "marketingroi",
          this.market.id.toLowerCase()
        )
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.marketingRio.data[0].data.push(res[i].base);
            this.marketingRio.data[1].data.push(res[i].short);
            this.marketingRio.data[2].data.push(res[i].long);
            this.marketingRio.xAxis.categories.push(res[i].name);
          }
        });
    },
    getStaticsData() {
      this.salseStatics = [];
      aimoSolutionsServices
        .getChartData(
          "actual vs prediction",
          "/b2c/in/",
          "salesstatistics",
          "no"
        )
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.salseStatics.push({
              name: res[i].name,
              value: res[i].value,
              className: this.classess[i],
            });
          }
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },

    selectedDigitalDropdown(e) {
      if (e === this.digitalTitle || e === this.digitalTitle.text) {
        return;
      }
      this.getLevelThreeData(
        "digitalMedia",
        e.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "digital",
        true
      );
      this.digitalTitle = e;
    },
    selectedPrintDropdown(e) {
      if (e === this.printTitle || e === this.printTitle.text) {
        return;
      }
      this.getLevelThreeData(
        "printMedia",
        e.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "print",
        false
      );
      this.printTitle = e;
    },
    selectedRadioDropdown(e) {
      if (e === this.radioTitle || e === this.radioTitle.text) {
        return;
      }
      this.getLevelThreeData(
        "radioMedia",
        e.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "radio",
        false
      );
      this.radioTitle = e;
    },
    selectedSponsorshipDropdown(e) {
      if (e === this.sponsorshipTitle || e === this.sponsorshipTitle.text) {
        return;
      }
      this.getLevelThreeData(
        "sponsorshipMedia",
        e.text.toLowerCase(),
        "/b2c/in/",
        this.market.id,
        "",
        false
      );
      this.sponsorshipTitle = e;
    },
    selectedBillboardDropdown(e) {
      if (e === this.billboardTitle || e === this.billboardTitle.text) {
        return;
      }
      this.getLevelThreeData(
        "billboardMedia",
        e.text.toLowerCase(),
        "/b2c/in/",
        this.market.id,
        "",
        false
      );
      this.billboardTitle = e;
    },
    selectedContentDropdown(e) {
      if (e === this.contentTitle || e === this.contentTitle.text) {
        return;
      }
      this.getLevelThreeData(
        "contentMedia",
        e.text.toLowerCase(),
        "/b2c/in/",
        "no",
        "content",
        false
      );
      this.contentTitle = e;
    },
  },
};
</script>

<style scoped>
.card_body_padding {
  padding: 30px 24px !important;
}

.fit-content {
  height: fit-content;
}

.hide-breakdown {
  width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background: #f3f3f3;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
}

.border_right {
  border-right: 1px solid #ccc;
}

.border_right::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -11px;
  width: 21px;
  height: 20px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(134deg);
}

.border_top_divider {
  border-top: 1px solid #ccc;
}

.border_top_divider::after {
  content: "";
  display: block;
  position: absolute;
  top: 47.8%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  left: 25%;
}

.dropdown-breakdown {
  width: 210px;
  object-fit: contain;
  font-size: 15px;
  position: absolute;
  right: 15px;
}

.bottom_header {
  position: sticky;
  top: 70px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 5px 5px 0px;
}
</style>
